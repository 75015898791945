"use client";

import Link from "next/link";
import type { HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from "react";
import Spinner from "./Spinner";

/**
 * @param href string / url
 * @param propgate boolean that defaults to false and calls stopPropgation
 * @param onClick optional onClick
 * @param type optional but defaults to a button link. The options are: button | button-sm | green-button-sm | orange-hyperlink | hyperlink | error
 * @param className optional className for the Link comp
 *
 *  Applies default styling for a given Link type.
 */
const MincaLink = ({
  children,
  href,
  type = "button",
  className = "",
  propgate = false,
  disabled = false,
  loading = false,
  onClick,
  target,
  spinnerSize
}: {
  children: ReactNode;
  className?: string;
  type?: "button" | "button-sm" | "button-xs" | "green-button-sm" | "orange-hyperlink" | "hyperlink" | "error";
  propgate?: boolean;
  disabled?: boolean;
  loading?: boolean;
  spinnerSize?: number;
} & ({
  href: string;
  onClick?: MouseEventHandler<HTMLElement>;
  target?: HTMLAttributeAnchorTarget;
} | {
  href?: undefined;
  target?: undefined;
  onClick?: MouseEventHandler<HTMLElement>;
})) => {
  // defaults to button styling
  let styling = "button";
  if (type === "button-sm") {
    styling = "button-inline !text-body-xs";
  } else if (type === "button-xs") {
    styling = "button-inline !text-body-xs py-[2px]";
  } else if (type === "green-button-sm") {
    styling = "button-inline !text-body-xs !bg-green_primary hover:opacity-80";
  } else if (type === "hyperlink") {
    styling = "underline underline-offset-2 text-black_primary hover:opacity-80";
  } else if (type === "orange-hyperlink") {
    styling = "underline underline-offset-2 text-orange_cone hover:opacity-80";
  } else if (type === "error") {
    styling = "text-red_error hover:opacity-80";
  }
  const handleClick: MouseEventHandler<HTMLElement> = e => {
    if (!propgate) {
      e.stopPropagation();
    }
    if (href) {
      onClick?.(e);
    } else {
      onClick?.(e);
    }
  };
  if (href) {
    return <Link className={`${styling} ${className}`} onClick={handleClick} href={disabled ? {} : href} target={target} aria-disabled={disabled || loading} data-is-loading={loading}>
        {loading ? <Spinner size={spinnerSize || 16} /> : children}
      </Link>;
  } else if (type.includes("button")) {
    return <button className={`${styling} ${className}`} onClick={handleClick} disabled={disabled} aria-disabled={disabled || loading} data-is-loading={loading}>
        {loading ? <Spinner size={spinnerSize ? spinnerSize : type.includes("button-sm") ? 12 : type.includes("button-inline") ? 14 : 16} /> : children}
      </button>;
  } else {
    // For eveything else, return a span
    return <span className={`${styling} hover:cursor-pointer aria-[disabled=true]:text-opacity-30 ${className}`} onClick={handleClick} aria-disabled={disabled || loading} data-is-loading={loading}>
        {loading ? <Spinner size={spinnerSize || 14} /> : children}
      </span>;
  }
};
export default MincaLink;